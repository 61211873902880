
import { computed, defineComponent, PropType } from 'vue'

const pagination = {
  position: 'top',
  showSizeChanger: true,
  showQuickJumper: true,
}

export default defineComponent({
  name: 'TableImportPreview',
  props: {
    importColumns: {
      type: Object as PropType<Array<Object> | null>,
      default: null,
    },
    importDataSource: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const columns = computed(() => props.importColumns)
    const dataSource = computed(() => props.importDataSource)

    return {
      columns,
      dataSource,
      pagination,
    }
  },
})
