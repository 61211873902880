<template>
  <div>
    <div v-if="columns" class="preview-table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :bordered="true"
        :row-key="(rec, idx) => idx"
        :scroll="{ x: true }"
        :pagination="pagination"
      >
      </a-table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

const pagination = {
  position: 'top',
  showSizeChanger: true,
  showQuickJumper: true,
}

export default defineComponent({
  name: 'TableImportPreview',
  props: {
    importColumns: {
      type: Object as PropType<Array<Object> | null>,
      default: null,
    },
    importDataSource: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const columns = computed(() => props.importColumns)
    const dataSource = computed(() => props.importDataSource)

    return {
      columns,
      dataSource,
      pagination,
    }
  },
})
</script>

<style lang="scss">
.preview-table .ant-pagination-item,
.preview-table .ant-pagination-jump-next,
.preview-table .ant-pagination-jump-prev {
  display: inline-block !important;
}
</style>
