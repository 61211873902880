<template>
  <div>
    <div class="card card-top card-top-primary">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">
            <strong>Import</strong>
          </h5>
        </div>
        <!-- Add actions here if needed -->
      </div>
      <div>
        <div class="card-body">
          <div class="mb-3">
            <!-- TODO: Add Error Alert also. -->
            <a-alert
              :message="alertMessage"
              :type="fileInfo?.importId ? 'success' : 'warning'"
              show-icon
              closable
              v-if="alertMessage"
            />
          </div>
          <div class="row mb-4">
            <div class="col-md-6" style="border-right: 1px solid #f0f0f0">
              <div class="row">
                <div class="col-md-10">
                  <a-select
                    :disabled="fileList.length > 0"
                    :default-value="entity"
                    placeholder="Select an entity to import"
                    @change="handleEntitySelect"
                    :dropdown-match-select-width="false"
                    style="width: 220px"
                  >
                    <a-select-option value="consignments">
                      Consignments
                    </a-select-option>
                    <a-select-option value="customers">
                      Customers
                    </a-select-option>
                    <a-select-option value="vendors">
                      Vendors
                    </a-select-option>
                    <a-select-option value="vendorServices">
                      Services
                    </a-select-option>
                    <a-select-option value="zones">
                      Zones(Pincode)
                    </a-select-option>
                    <a-select-option value="rates">
                      Rates
                    </a-select-option>
                  </a-select>
                  <a-button
                    type="link"
                    @click="handleSampleDownload"
                    :loading="isSampleDownloading"
                  >
                    <DownloadOutlined v-show="!isSampleDownloading" />Sample File
                  </a-button>
                </div>
              </div>
              <div class="row" v-if="entity === 'consignments'">
                <div class="col-md-4">
                  <div class="mt-2">
                    <div class="d-flex justify-content-between">
                      <a-tooltip title="Override Customer">
                        <a-switch v-model:checked="overrideConsignment" />
                      </a-tooltip>
                      <a-tooltip title="Override Weight">
                        <a-switch v-model:checked="overrideWeight" />
                      </a-tooltip>
                      <a-tooltip title="Override Amount">
                        <a-switch v-model:checked="overrideAmount" />
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <div class="pt-5">
                    <a-upload-dragger
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      :before-upload="beforeUpload"
                      :multiple="false"
                      :open-file-dialog-on-click="fileList.length === 0"
                      v-model:file-list="fileList"
                      :disabled="isBrowseDisabled"
                    >
                      <p class="ant-upload-drag-icon">
                        <i class="fa fa-2x fa-upload text-primary mr-1" />
                        <i class="fa fa-2x fa-table mr-1" />
                        <i class="fa fa-2x fa-file-excel-o text-success" />
                      </p>
                      <p class="ant-upload-text">Click or drag file to this area to upload</p>
                      <p class="ant-upload-hint p-3">
                        Current supported file types are "XLSX" and "XLS". Please select an entity
                        type to upload. Ensure that the uploaded file is of correct format.
                      </p>
                    </a-upload-dragger>
                  </div>
                </div>

                <div class="col-md-8">
                  <a-button
                    type="primary"
                    :disabled="fileList.length === 0 || !isJsonSizeValid"
                    :loading="uploading"
                    class="mt-5"
                    @click="handleUpload"
                  >
                    {{ uploading ? 'Uploading' : 'Start Upload' }}
                  </a-button>
                  <a-button
                    :disabled="fileList.length === 0"
                    class="ml-4 mt-5"
                    @click="handleReset"
                  >
                    Reset
                  </a-button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <a-spin :spinning="spin">
                <a-descriptions
                  title="Import Details"
                  bordered
                  :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
                  size="small"
                >
                  <a-descriptions-item label="Selected Entity" :span="3">
                    {{ entity }}
                  </a-descriptions-item>
                  <a-descriptions-item label="File Name" :span="3">
                    {{ fileInfo?.name || 'N/A' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="Total Rows" :span="3">
                    {{ fileInfo?.totalRows || 'N/A' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="File Size" :span="3">
                    {{ fileInfo?.sizeValue || 'N/A' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="Data Size" :span="3">
                    {{ fileInfo?.dataSizeValue || 'N/A' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="Import ID" :span="3">
                    <strong>{{ fileInfo?.importId || 'N/A' }}</strong>
                  </a-descriptions-item>
                </a-descriptions>
              </a-spin>
            </div>
          </div>
          <div>
            <a-divider>Preview Data</a-divider>
            <table-import-preview :import-data-source="fileData" :import-columns="importColumns" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { DownloadOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { message } from 'ant-design-vue'
import * as utils from '@/services/helpers'
import { useRoute } from 'vue-router'
import TableImportPreview from './TableImportPreview.vue'
import { OperationResponse } from '@/types/appContracts/OperationResponse'
import { DataImportContract } from '@/types/appContracts/DataImportContract'
import { IImportFileInfo } from '@/types/interfaces/IImportFileInfo'
import { IShipmentImportAdditional } from '@/types/interfaces/IShipmentImportAdditional'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}

export default defineComponent({
  components: {
    DownloadOutlined,
    TableImportPreview,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const entity = ref((route.query.entity as string) || 'consignments')
    const validEntities = ref<Array<string>>([])
    const fileList = ref<Array<File>>([])
    const fileData = ref<Array<any>>([])
    const fileInfo = ref<IImportFileInfo | null>(null)
    const spin = ref(false)
    const isBrowseDisabled = ref(false)
    const uploading = ref(false)
    const importColumns = ref<Array<Object> | null>(null)
    const alertMessage = ref<string | null>(null)
    const isSampleDownloading = ref(false)
    const isJsonSizeValid = ref(true)
    const allowedJsonSize = 5 * 1024 * 1024
    const overrideConsignment = ref<boolean>(false)
    const overrideWeight = ref<boolean>(false)
    const overrideAmount = ref<boolean>(false)

    const handleEntitySelect = async (value: string) => {
      entity.value = value
      overrideConsignment.value = false
      overrideAmount.value = false
      overrideWeight.value = false
      checkIfEntitySupported()
    }

    const checkIfEntitySupported = () => {
      // NOTE: watchEffect can also be used
      isBrowseDisabled.value = validEntities.value.includes(entity.value) ? false : true
    }

    const handleReset = () => {
      fileList.value = []
      fileData.value = []
      fileInfo.value = null
      importColumns.value = null
      isBrowseDisabled.value = false
      isJsonSizeValid.value = true
      alertMessage.value = null
    }

    const beforeUpload = (file: File) => {
      // NOTE: If we make beforeUpload async, then it will not get the "false" immediately and fail with POST
      isBrowseDisabled.value = true
      alertMessage.value = null

      // fileList.value = [...fileList.value, file] // ???
      readImportFile(file)
      return false
    }

    const readImportFile = async (file: File) => {
      spin.value = true
      const jsonArray: Array<any> = await store.dispatch(
        `tools/${actions.ToolsAction.READ_IMPORT_FILE}`,
        file,
      )
      if (!jsonArray || !jsonArray.length) {
        // NOTE: jsonArray will be null when error occurs.
        message.error('Some Error Occurred')
        handleReset()
      } else {
        const dataSize = new TextEncoder().encode(JSON.stringify(jsonArray)).length
        fileInfo.value = {
          name: file.name,
          sizeInBytes: file.size,
          sizeValue: utils.formatBytes(file.size),
          totalRows: jsonArray.length,
          type: file.type,
          importId: null,
          dataSizeInBytes: dataSize,
          dataSizeValue: utils.formatBytes(dataSize),
        }
        fileData.value = jsonArray
        if (dataSize > allowedJsonSize) {
          isJsonSizeValid.value = false
          alertMessage.value = 'Please select a file which has less than 5 MB data.'
        }
        importColumns.value = utils.GetAntColumnsFromArray(Object.keys(jsonArray[0]))
      }
      spin.value = false
    }

    const handleUpload = async () => {
      uploading.value = true
      const importData: DataImportContract = {
        entity: entity.value,
        data: fileData.value,
        fileInfo: fileInfo.value,
        additionalData:
          entity.value === 'consignments'
            ? ({
                overrideCustomerId: overrideConsignment.value,
                overrideWeight: overrideWeight.value,
                overrideAmount: overrideAmount.value,
              } as IShipmentImportAdditional)
            : null,
      }

      const resp: OperationResponse<string> = await store.dispatch(
        `tools/${actions.ToolsAction.IMPORT_ENTITY_DATA}`,
        {
          importData,
        },
      )

      if (resp.result?.isSuccess) {
        if (fileInfo.value) {
          fileInfo.value.importId = resp.responseData as string
        }
        alertMessage.value = `Data Imported Successfully. Import ID for reference is: ${resp.responseData}`
      } else {
        alertMessage.value = resp.result?.errorMessage || 'Some Error Occurred.'
      }

      fileList.value = []
      uploading.value = false
      isBrowseDisabled.value = false
      overrideConsignment.value = false
    }

    const handleSampleDownload = async () => {
      isSampleDownloading.value = true
      const url = await store.dispatch(
        `tools/${actions.ToolsAction.DOWNLOAD_ENTITY_SAMPLE}`,
        entity.value,
      )
      if (url) {
        window.open(url, '_blank')
      }
      isSampleDownloading.value = false
    }

    onMounted(async () => {
      validEntities.value = await store.dispatch(
        `tools/${actions.ToolsAction.GET_SUPPORTED_ENTITIES}`,
      )
      checkIfEntitySupported()
    })

    return {
      entity,
      fileList,
      isBrowseDisabled,
      labelCol,
      wrapperCol,
      handleEntitySelect,
      handleReset,
      beforeUpload,
      handleUpload,
      uploading,
      fileData,
      fileInfo,
      spin,
      importColumns,
      alertMessage,
      handleSampleDownload,
      isSampleDownloading,
      isJsonSizeValid,
      overrideConsignment,
      overrideWeight,
      overrideAmount,
    }
  },
})
</script>

<style></style>
