
import { defineComponent, onMounted, ref } from 'vue'
import { DownloadOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import * as actions from '@/store/actions.type'
import { message } from 'ant-design-vue'
import * as utils from '@/services/helpers'
import { useRoute } from 'vue-router'
import TableImportPreview from './TableImportPreview.vue'
import { OperationResponse } from '@/types/appContracts/OperationResponse'
import { DataImportContract } from '@/types/appContracts/DataImportContract'
import { IImportFileInfo } from '@/types/interfaces/IImportFileInfo'
import { IShipmentImportAdditional } from '@/types/interfaces/IShipmentImportAdditional'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}

export default defineComponent({
  components: {
    DownloadOutlined,
    TableImportPreview,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const entity = ref((route.query.entity as string) || 'consignments')
    const validEntities = ref<Array<string>>([])
    const fileList = ref<Array<File>>([])
    const fileData = ref<Array<any>>([])
    const fileInfo = ref<IImportFileInfo | null>(null)
    const spin = ref(false)
    const isBrowseDisabled = ref(false)
    const uploading = ref(false)
    const importColumns = ref<Array<Object> | null>(null)
    const alertMessage = ref<string | null>(null)
    const isSampleDownloading = ref(false)
    const isJsonSizeValid = ref(true)
    const allowedJsonSize = 5 * 1024 * 1024
    const overrideConsignment = ref<boolean>(false)
    const overrideWeight = ref<boolean>(false)
    const overrideAmount = ref<boolean>(false)

    const handleEntitySelect = async (value: string) => {
      entity.value = value
      overrideConsignment.value = false
      overrideAmount.value = false
      overrideWeight.value = false
      checkIfEntitySupported()
    }

    const checkIfEntitySupported = () => {
      // NOTE: watchEffect can also be used
      isBrowseDisabled.value = validEntities.value.includes(entity.value) ? false : true
    }

    const handleReset = () => {
      fileList.value = []
      fileData.value = []
      fileInfo.value = null
      importColumns.value = null
      isBrowseDisabled.value = false
      isJsonSizeValid.value = true
      alertMessage.value = null
    }

    const beforeUpload = (file: File) => {
      // NOTE: If we make beforeUpload async, then it will not get the "false" immediately and fail with POST
      isBrowseDisabled.value = true
      alertMessage.value = null

      // fileList.value = [...fileList.value, file] // ???
      readImportFile(file)
      return false
    }

    const readImportFile = async (file: File) => {
      spin.value = true
      const jsonArray: Array<any> = await store.dispatch(
        `tools/${actions.ToolsAction.READ_IMPORT_FILE}`,
        file,
      )
      if (!jsonArray || !jsonArray.length) {
        // NOTE: jsonArray will be null when error occurs.
        message.error('Some Error Occurred')
        handleReset()
      } else {
        const dataSize = new TextEncoder().encode(JSON.stringify(jsonArray)).length
        fileInfo.value = {
          name: file.name,
          sizeInBytes: file.size,
          sizeValue: utils.formatBytes(file.size),
          totalRows: jsonArray.length,
          type: file.type,
          importId: null,
          dataSizeInBytes: dataSize,
          dataSizeValue: utils.formatBytes(dataSize),
        }
        fileData.value = jsonArray
        if (dataSize > allowedJsonSize) {
          isJsonSizeValid.value = false
          alertMessage.value = 'Please select a file which has less than 5 MB data.'
        }
        importColumns.value = utils.GetAntColumnsFromArray(Object.keys(jsonArray[0]))
      }
      spin.value = false
    }

    const handleUpload = async () => {
      uploading.value = true
      const importData: DataImportContract = {
        entity: entity.value,
        data: fileData.value,
        fileInfo: fileInfo.value,
        additionalData:
          entity.value === 'consignments'
            ? ({
                overrideCustomerId: overrideConsignment.value,
                overrideWeight: overrideWeight.value,
                overrideAmount: overrideAmount.value,
              } as IShipmentImportAdditional)
            : null,
      }

      const resp: OperationResponse<string> = await store.dispatch(
        `tools/${actions.ToolsAction.IMPORT_ENTITY_DATA}`,
        {
          importData,
        },
      )

      if (resp.result?.isSuccess) {
        if (fileInfo.value) {
          fileInfo.value.importId = resp.responseData as string
        }
        alertMessage.value = `Data Imported Successfully. Import ID for reference is: ${resp.responseData}`
      } else {
        alertMessage.value = resp.result?.errorMessage || 'Some Error Occurred.'
      }

      fileList.value = []
      uploading.value = false
      isBrowseDisabled.value = false
      overrideConsignment.value = false
    }

    const handleSampleDownload = async () => {
      isSampleDownloading.value = true
      const url = await store.dispatch(
        `tools/${actions.ToolsAction.DOWNLOAD_ENTITY_SAMPLE}`,
        entity.value,
      )
      if (url) {
        window.open(url, '_blank')
      }
      isSampleDownloading.value = false
    }

    onMounted(async () => {
      validEntities.value = await store.dispatch(
        `tools/${actions.ToolsAction.GET_SUPPORTED_ENTITIES}`,
      )
      checkIfEntitySupported()
    })

    return {
      entity,
      fileList,
      isBrowseDisabled,
      labelCol,
      wrapperCol,
      handleEntitySelect,
      handleReset,
      beforeUpload,
      handleUpload,
      uploading,
      fileData,
      fileInfo,
      spin,
      importColumns,
      alertMessage,
      handleSampleDownload,
      isSampleDownloading,
      isJsonSizeValid,
      overrideConsignment,
      overrideWeight,
      overrideAmount,
    }
  },
})
